
import { defineComponent, ref } from 'vue';

import { siteURL } from '@/plugins/config';
import { Modal } from 'ant-design-vue';
import { useTable, shopTable } from './index';
import upload from '@/components/modules/upload/index.vue';
import YImage from '@/components/modules/image/index.vue';
import Price from '@/components/modules/Price.vue';
import QRCode from 'qrcode';

export default defineComponent({
  name: 'merchantList',
  components: { YImage, upload, Price, AModal: Modal },
  setup() {
    const visible = ref(false);
    const qrCodeUrl = ref('');
    const shopInfo = ref({ ShopName: '' });
    function handleQrCode(el: any) {
      shopInfo.value = el;
      QRCode.toDataURL(el.WeixinQrcode).then((url: string) => {
        visible.value = true;
        qrCodeUrl.value = url;
      });
    }
    //base64转blob
    function base64ToBlob(code: any) {
      let parts = code.split(';base64,');
      let contentType = parts[0].split(':')[1];
      let raw = window.atob(parts[1]);
      let rawLength = raw.length;

      let uInt8Array = new Uint8Array(rawLength);

      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], { type: contentType });
    }

    function downloadFile() {
      let aLink = document.createElement('a');
      let blob = base64ToBlob(qrCodeUrl.value); //new Blob([content]);

      let evt = document.createEvent('HTMLEvents');
      evt.initEvent('click', true, true); //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
      aLink.download = shopInfo.value.ShopName;
      aLink.href = URL.createObjectURL(blob);

      // aLink.dispatchEvent(evt);
      aLink.click();
    }

    return {
      shopInfo,
      visible,
      qrCodeUrl,
      siteURL,
      handleQrCode,
      downloadFile,
      ...useTable(),
      ...shopTable(),
    };
  },
});
